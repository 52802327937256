import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer>
      <div className="container-bosx">
        <div className="container-box bg-footer">
          <div className="row justify-content-center">
            <div className="col-md-3 mt-5">
              <p className="h4">NAVIGATION</p>
              <div className="mt-4 footer-links">
                <Link to="/" aria-label="home">
                  {" "}
                  <i className="bi bi-chevron-right me-2 text-success"></i> Home
                </Link>
                <Link to="/about-us">
                  {" "}
                  <i className="bi bi-chevron-right me-2 text-success"></i>{" "}
                  About Us
                </Link>
                <Link to="/coating">
                  {" "}
                  <i className="bi bi-chevron-right me-2 text-success"></i> Our
                  Services
                </Link>
                <Link to="/enquiry">
                  {" "}
                  <i className="bi bi-chevron-right me-2 text-success"></i>{" "}
                  Inquiry Now
                </Link>
                <Link to="/contact-us">
                  {" "}
                  <i className="bi bi-chevron-right me-2 text-success"></i>{" "}
                  Contact Us
                </Link>
                <Link to="/privacy-policy">
                  {" "}
                  <i className="bi bi-chevron-right me-2 text-success"></i>{" "}
                  Privacy Policy
                </Link>
              </div>
            </div>
            <div className="col-md-4 mt-5">
              <p className="h4">CONTACT DETAILS</p>
              <div className="mt-4 footer-links">
                <p className="text-white">
                  <i className="bi bi-telephone-inbound me-3"> </i>
                  <Link
                    to="tell:+91 9952186877"
                    className="text-decoration-none d-inline paragraph pe-2"
                  >
                    {" "}
                    +91 9952186877{" "}
                  </Link>{" "}
                  |
                  <Link
                    to="tell:+91 9384370697"
                    className="text-decoration-none d-inline paragraph ps-2"
                  >
                    {" "}
                    +91 9384370697{" "}
                  </Link>
                </p>

                <Link to="mailto:marketing@glamourdecor.in" className="d-flex">
                  {" "}
                  <i className="bi bi-envelope me-3"></i>{" "}
                  mahesh@glamourdecor.in
                </Link>

                <Link to="mailto:mahesh.glamourdecor@gmail.com" className="d-flex">
                  {" "}
                  <i className="bi bi-envelope me-3"></i>{" "}
                  mahesh.glamourdecor@gmail.com
                </Link>

                <div className="social-links mt-4">
                  <Link
                    aria-label="facebook"
                    to="https://www.facebook.com/profile.php?id=61550116506147"
                    target="_blank"
                  >
                    <i className="bi bi-facebook"></i>
                  </Link>
                  <Link
                    aria-label="instagram"
                    to="https://www.instagram.com/glamourdecorlunavadodara/?hl=en"
                    target="_blank"
                  >
                    <i className="bi bi-instagram"></i>
                  </Link>
                  <Link
                    aria-label="youtube"
                    to="https://www.youtube.com/channel/UC6g82a3mSpQk8MKiZKbcYmA"
                    target="_blank"
                  >
                    <i className="bi bi-youtube"></i>
                  </Link>
                  <Link
                    aria-label="linkedin"
                    to="https://www.linkedin.com/in-vadodara-34b472289/"
                    target="_blank"
                  >
                    <i className="bi bi-linkedin"></i>
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-md-4 mt-5">
              <p className="h4">ADDRESS</p>
              <div className="mt-4 footer-links">
                <p
                  className="paragraph-2 d-flex text-white"
                  style={{ fontSize: "16.5px" }}
                >
                  <i className="bi bi-geo-alt me-3"></i>
                  741, Luna Rd, Taluko: Padra, District: Vadodara, Pincode:
                  391440 ,Gujarat.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="text-center container-box py-4  bg-footer">
          <div className="paragraph-2 p-2 ">
            <span className="text-white">
              {" "}
              Copyrights &copy; 2024 - Glamour Decor Designed by
            </span>
            <Link
              to="https://www.zithas.com/"
              target="_blank"
              className="text-decoration-none"
              style={{ color: "#29b350" }}
            >
              {" "}
              <span>Zithas Technologies</span>{" "}
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
