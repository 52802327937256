import axios from "axios";

class WebService {
  GetApiCall(url) {
    return axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    });
  }
  postApiCall(url, data) {
    return axios.post(url, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

const SERVER = "https://glamourdecor.in/backend/api";

export const urls = {
  HOMEAPI: `${SERVER}/home`,
  BANNER: `${SERVER}/banner`,
  ABOUTUS: `${SERVER}/about`,
  OURSERVICES: `${SERVER}/ourservice`,
  PORTFOLIO: `${SERVER}/capabilities`,
  CAREER: `${SERVER}/career_section`,
  CONTACTGET: `${SERVER}/contact`,
  CAREERMAIL: `${SERVER}/career`,
  ENQUIRYMAIL: `${SERVER}/inquiry`,
  CONTACT: `${SERVER}/contactus`,
  BLOGDATA: `${SERVER}/blog`,
  CATEGORIESDROP: `${SERVER}/categories`,
  PRODUCTS: `${SERVER}/products_category`,
  GETPRODUCT: `${SERVER}/product`,
  ENQUIRY: `${SERVER}/enquiry_section`,
  PRIVACYPOLICY: `${SERVER}/privacy_policy`,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default new WebService();
