import React, { useEffect, useRef, useState } from "react";

// slick slider
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import AOS from "aos";
import "aos/dist/aos.css";
import "../../assets/css/Home.css";
import "../../assets/css/Service.css";

const Banner = ({ text, header, banner }) => {
  const [slidIndex, setSlidIndex] = useState(0);

  useEffect(() => {
    AOS.init({
      offset: 100, // offset (in px) from the original trigger point
      duration: 800, // values from 0 to 3000, with step 50ms
      easing: "ease-out",
      once: false,
    });
  }, [slidIndex]);

  const sliderRef = useRef();

  const handleOnClick = (index) => {
    sliderRef.current.slickGoTo(index);
  };

  return (
    <div className="position-relative">
      <div
        className="banner-slider"
        style={{ backgroundImage: `url(${banner})` }}
      >
        <div className="banner-effect">
          <div
            className="container-box padding-banner "
            data-aos="fade-left"
            data-aos-delay="100"
          >
            <div className="text-white banner-text text-right">
              <p className="mb-1">{text}</p>
              <h1 className="fw-bold display-4 text-capitalize">{header}</h1>
              <div className="d-flex justify-content-end">
                <p className="col-5 font-19"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
